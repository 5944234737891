<template>
  <v-row class="mx-0" data-testid="container-license-details">
    <v-col cols="12" md="6" lg="6" v-if="license.request" class="mx-0">
      <v-card class="my-2" data-testid="container-request-info">
        <v-card-title>Request info</v-card-title>
        <v-card-text>
          <table class="v-datatable v-table theme--dark">
            <tbody>
              <tr>
                <td class="pr-2"><strong>Email:</strong></td>
                <td data-testid="license-reques-email">
                  {{ license.request.email }}
                </td>
              </tr>
              <tr>
                <td class="pr-2"><strong>Company name:</strong></td>
                <td data-testid="license-reques-company">
                  {{ license.request.company_name }}
                </td>
              </tr>
              <tr>
                <td class="pr-2"><strong>PC name:</strong></td>
                <td data-testid="license-reques-computer-name">
                  {{ license.request.computer_name }}
                </td>
              </tr>
              <tr>
                <td class="pr-2"><strong>Country:</strong></td>
                <td data-testid="license-reques-country">
                  {{ license.request.country }}
                </td>
              </tr>
              <tr>
                <td class="pr-2"><strong>Ip:</strong></td>
                <td data-testid="license-reques-ip-address">
                  {{ license.request.ip_address }}
                </td>
              </tr>
              <tr class="pr-2">
                <td><strong>Mac Address:</strong></td>
                <td data-testid="license-reques-mac-address">
                  {{ license.request.mac_address }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="6"
      lg="6"
      class="mx-0"
      v-if="license.product"
      data-testid="container-hs-product-info"
    >
      <v-card class="my-2">
        <v-card-title>HubSpot Product Info</v-card-title>
        <v-card-text>
          <table class="v-datatable v-table theme--dark">
            <tbody>
              <tr>
                <td class="pr-2"><strong>Name:</strong></td>
                <td data-testid="hs-product-name">
                  {{ license.product.properties.name }}
                </td>
              </tr>
              <tr>
                <td class="pr-2"><strong>Deal Key:</strong></td>
                <td data-testid="hs-deal-id">
                  {{ license.product.hs_deal_id }}
                </td>
              </tr>
              <tr>
                <td class="pr-2"><strong>Product Key:</strong></td>
                <td data-testid="hs-product-id">
                  {{ license.product.properties.hs_product_id }}
                </td>
              </tr>
              <tr>
                <td class="pr-2"><strong>Contact email:</strong></td>
                <td data-testid="hs-contact-email">
                  {{ license.product.hs_contact_email }}
                </td>
              </tr>
              <tr>
                <td class="pr-2"><strong>End date:</strong></td>
                <td data-testid="hs-product-end-date">
                  {{ license.product.properties.product_end_date | dateFormat }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="6"
      lg="6"
      class="mx-0"
      v-if="license.activation_code || license.hardware_key"
      data-testid="container-license-info"
    >
      <v-card class="my-2">
        <v-card-title>License info</v-card-title>
        <v-card-text>
          <table class="v-datatable v-table theme--dark">
            <tbody>
              <tr v-if="license.activation_code">
                <td class="pr-2"><strong>Activation code:</strong></td>
                <td data-testid="license-info-activation-code">
                  {{ license.activation_code }}
                </td>
              </tr>
              <tr v-if="license.hardware_key">
                <td class="pr-2"><strong>Hardware key:</strong></td>
                <td class="break-word" data-testid="license-info-hardware-key">
                  {{ license.hardware_key }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    license: {
      type: [Object],
    },
  },
}
</script>

<style lang="scss" scoped>
td.break-word {
  overflow-wrap: break-word;
  max-width: 600px;
}
</style>
