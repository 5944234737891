<template>
  <div>
    <v-card key="eventsBundlesList">
      <v-toolbar>
        <v-card-title>Licenses</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$can('create', 'Superadmin') || $can('create', 'licenses')"
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          to="/store/license/create"
          data-testid="btn-create-license"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <DataTableExtended
          :headers="headersTable"
          :items="licensesList"
          item-key="_key"
          class="elevation-0 border"
          :calculate-widths="true"
          :loading="showTableLoader"
          :loading-text="showTableLoader ? 'Loading…' : 'No data available'"
          sortable
          :multi-sort="false"
          :server-items-length="licensesCount"
          v-on:init-table-data="getLicensesList"
          ref="refTableLicenses"
          show-expand
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          data-testid="table-licenses"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              style="background-color: #272727"
              class="px-0"
            >
              <LicenseDetails :license="item" />
            </td>
          </template>

          <template v-slot:top>
            <v-row class="pa-3">
              <v-col cols="12" md="3"> </v-col>
              <v-col v-if="isSuperUser" cols="12" md="3" class="text-right">
                <SelectCompaniesAsync
                  v-model="filterByCompany"
                  outlined
                  label="Filter by company"
                  :multiple="false"
                  item-value="hs_company_id"
                  item-text="name"
                  field="group_key"
                  :menu-props="{ maxHeight: 304 }"
                  clearable
                  keep="_key,name,hs_company_id"
                  data-testid="filter-company"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                :offset-md="$can('read', 'Superadmin') ? 0 : 3"
              >
                <SelectEnginesAsync
                  v-model="filterByEngine"
                  outlined
                  label="Filter by engine"
                  :multiple="false"
                  item-value="_key"
                  item-text="name"
                  clearable
                  field="engine"
                  :menu-props="{ maxHeight: 304 }"
                  keep="_key,name,serial_number"
                  data-testid="filter-engine"
                />
              </v-col>
              <v-col cols="12" md="3" class="text-right">
                <v-autocomplete
                  v-model="filterStatus"
                  :items="licenseStatuses"
                  item-text="state"
                  item-value="id"
                  label="Filter by status"
                  outlined
                  hide-details
                  :menu-props="{ maxHeight: 304 }"
                  clearable
                  data-testid="filter-status"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.type`]="{ item }">
            <div data-testid="license-type">
              {{ getLicenseTypeName(item.type) }}
            </div>
          </template>

          <template v-slot:[`item.product_key`]="{ item }">
            <div data-testid="product-key">
              {{ item.product_key }}
            </div>
          </template>

          <template v-slot:[`item.hardware_id`]="{ item }">
            <div data-testid="hardware-id">
              {{ item.hardware_id }}
            </div>
          </template>

          <template v-slot:[`item.serial_number`]="{ item }">
            <div data-testid="engine-name">
              {{ getEngineName(item) }}
            </div>
          </template>

          <template v-slot:[`item.date_create`]="{ item }">
            <div data-testid="license-created-datetime">
              {{ item.date_create | unixDateTimeFormat }}
            </div>
          </template>

          <template v-slot:[`item.is_active`]="{ item }">
            <v-chip
              small
              :color="getStatusInfo(item).color"
              text-color="white"
              class="chip-status-webengine"
              data-testid="license-status"
            >
              {{ getStatusInfo(item).text | capitalize }}
            </v-chip>
          </template>

          <template v-slot:[`item.menu`]="{ item }">
            <div
              class="text-center d-flex align-center justify-space-around"
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              <v-tooltip
                top
                v-if="
                  canBeLicenseRevoked(item) &&
                  ($can('update', 'licenses_revoke') || isSuperUser)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="Pallette.actionButtons.delete"
                    v-on="on"
                    @click="revokeLicense(item)"
                    class="mx-1"
                    data-testid="btn-revoke-license"
                  >
                    <v-icon size="22">mdi-lock-remove</v-icon>
                  </v-btn>
                </template>
                <span>Revoke license</span>
              </v-tooltip>
            </div>
          </template>
        </DataTableExtended>
      </v-card-text>
    </v-card>
    <Confirm ref="confirmDialog"></Confirm>
  </div>
</template>

<script>
/**
 * Component for list of licenses
 */
import { mapGetters } from "vuex"
import Confirm from "@/components/Confirm"
import SelectEnginesAsync from "@//components/selects/SelectEnginesAsync"
import DataTableExtended from "@/components/table/DataTableExtended.vue"
import SelectCompaniesAsync from "@/components/selects/SelectCompaniesAsync"
import LicenseDetails from "./LicenseDetails"
import StoreMixin from "@/mixins/store/common.js"

export default {
  components: {
    Confirm,
    SelectEnginesAsync,
    DataTableExtended,
    SelectCompaniesAsync,
    LicenseDetails,
  },

  mixins: [StoreMixin],

  data() {
    return {
      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: "Type",
          align: "left",
          value: "type",
          sortable: false,
        },
        {
          text: "Product key",
          align: "left",
          value: "product_key",
          sortable: false,
          width: 150,
        },
        {
          text: "Hardware ID",
          align: "left",
          value: "hardware_id",
          sortable: false,
          width: 150,
        },
        {
          text: "Engine",
          align: "left",
          value: "serial_number",
          sortable: false,
        },
        {
          text: "Created",
          align: "center",
          value: "date_create",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          value: "is_active",
          sortable: false,
          width: 150,
        },
        {
          text: "",
          value: "menu",
          align: "center",
          sortable: false,
          class: "th-clear-padding",
          width: "1%",
        },
      ],
      filterByCompany: null,
      filterByEngine: null,
      filterStatus: null,
      expanded: [],
      singleExpand: false,
      statuses: [],
    }
  },

  watch: {
    filterByCompany: function () {
      this.getLicensesList()
    },
    filterByEngine: function () {
      this.getLicensesList()
    },
    filterStatus: function () {
      this.getLicensesList()
    },
  },

  mounted() {
    this.$store.dispatch("getLicenseStatusesList")
  },

  methods: {
    getLicensesList: function () {
      let self = this
      let getParams = self.$refs.refTableLicenses.getTableServerParams()
      if (self.filterByCompany) getParams.hs_company_id = self.filterByCompany
      if (self.filterByEngine) {
        getParams.serial_number = self.filterByEngine.serial_number
      }
      if (self.filterStatus) getParams.status = self.filterStatus
      self.$store
        .dispatch("getLicensesList", { params: getParams })
        .then(() => {
          // @todo think about move this logic in DataTableExtended component
          if (self.licensesList.length === 0) {
            self.$refs.refTableLicenses.options.page = 1
          }
        })
        .catch(() => {})
    },
    getEngineName(item) {
      return item.engine ? item.engine.name : item.serial_number
    },
    revokeLicense(item) {
      let self = this
      self.$refs.confirmDialog
        .open("Delete", "Are you sure you want to revoke this license?", {
          color: "red darken-4",
          width: 310,
        })
        .then((confirm) => {
          if (confirm) {
            self.$store
              .dispatch("revokeLicense", {
                hardware_key: item.hardware_key,
              })
              .then(() => {
                self.getLicensesList()
              })
          }
        })
    },
    canBeLicenseRevoked(item) {
      return (
        (item.is_active || item.status !== "ACTIVE") &&
        item.status !== "REVOKED" &&
        item.status !== "PENDING" &&
        item.hardware_key
      )
    },
    getStatusInfo(item) {
      let statusText = item.status ? item.status.toLowerCase() : "inactive"
      let chipsColorsData = {
        active: this.Pallette.statusChips.active,
        inactive: "grey darken-1",
        revoked: this.Pallette.statusChips.notActive,
        pending: this.Pallette.statusChips.pending,
      }
      return {
        color: chipsColorsData[statusText],
        text: statusText,
      }
    },
  },

  computed: {
    ...mapGetters({
      licensesList: "licensesList",
      licensesCount: "licensesCount",
      isSuperUser: "isSuperUser",
      currentUser: "currentUser",
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
      licenseStatuses: "licenseStatuses",
    }),

    headersTable() {
      let headersTable = this.headers
      if (this.isSuperUser) {
        let companyHeader = {
          text: "Company",
          align: "left",
          sortable: false,
          value: "company.name",
        }
        headersTable.splice(2, 0, companyHeader)
      }
      return headersTable
    },
  },
}
</script>
