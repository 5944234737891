<template>
  <div>
    <v-autocomplete
      v-bind="$attrs"
      v-on="$listeners"
      :search-input.sync="filterSearch"
      loading="filterEnginesLoading"
      :items="getEnginesCompanyList"
      :cache-items="true"
      @select="selectValue"
      v-model="valueSelect"
      ref="autocompleteEngines"
      return-object
    >
      <template v-slot:append-item>
        <div
          v-if="lastPageOfgetEnginesListComputed && !filterSearch"
          v-observe-visibility="{
            callback: visibilityChanged,
          }"
          class="selectPagination"
        >
          <span class="pa-2">Loading more items ...</span>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
/**
 * Component of select with list of engines that are loaded async by chunks, with search
 */
import { mapGetters } from "vuex"

export default {
  name: "SelectEnginesAsync",

  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String, Object],
    },
    customFilters: {
      type: Object,
    },
    keep: {
      type: String,
      default: "_key,name",
    },
  },

  data: () => ({
    filterSearch: null,
    selectPagination: {
      p: 1,
      pp: 20,
    },
  }),

  mounted: function () {
    this.fetchEngines(true)
  },

  watch: {
    selected(val) {
      this.$emit("update:selected", val)
    },
    filterSearch(val) {
      // This is fix for that
      if (val && val !== this.valueSelect?.name) {
        this.selectPagination.p = 1
        this.fetchEngines()
      }
    },
    value: {
      handler: function (val, oldVal) {
        if (val && val !== oldVal) {
          this.getDataForSelected()
        }
      },
      immediate: true,
    },
    customFilters: function (newVal) {
      if (Object.prototype.hasOwnProperty.call(newVal, "exclude")) {
        this.$refs.autocompleteEngines.cachedItems = []
        this.$store.dispatch("resetEngineCompanyList").then(() => {
          this.fetchEngines(true)
        })
      } else {
        this.fetchEngines(true)
      }
    },
  },

  methods: {
    visibilityChanged(e) {
      if (this.lastPageOfgetEnginesListComputed) {
        e && this.nextPage()
      }
    },
    startPage() {
      this.selectPagination.p = 1
    },
    nextPage() {
      let maxPages = Math.ceil(
        this.enginesCompanyListCount / this.selectPagination.pp
      )
      if (this.selectPagination.p <= maxPages - 1) {
        this.filterEnginesLoading = true
        this.selectPagination.p += 1
        this.fetchEngines()
      }
      this.filterEnginesLoading = false
    },
    fetchEngines(resetCache = false) {
      if (resetCache) {
        this.$refs.autocompleteEngines.cachedItems = []
        this.$store.dispatch("resetEngineCompanyList")
      }
      let fetchData = {
        params: {
          p: this.selectPagination.p,
          pp: this.selectPagination.pp,
          keep: this.keep,
        },
      }
      if (this.filterSearch) fetchData.params.search = this.filterSearch
      if (this.customFilters)
        fetchData.params = { ...fetchData.params, ...this.customFilters }
      this.$store.dispatch("enginesCompanyGetList", fetchData)
    },
    selectValue() {
      this.filterSearch = ""
    },
    getDataForSelected() {
      if (this.value?._key) {
        this.$store.dispatch("engineSelectedInlist", this.value._key)
      }
    },
  },

  computed: {
    ...mapGetters({
      getEnginesCompanyList: "getEnginesCompanyList",
      enginesCompanyListCount: "enginesCompanyListCount",
    }),
    lastPageOfgetEnginesListComputed() {
      let flag =
        this.enginesCompanyListCount -
          this.selectPagination.p * this.selectPagination.pp >
        0
      return flag
    },
    valueSelect: {
      get() {
        return this.value
      },
      set() {},
    },
  },
}
</script>
