<template>
  <div>
    <v-autocomplete
      v-bind="$attrs"
      v-on="$listeners"
      :search-input.sync="filterSearch"
      loading="filterEnginesLoading"
      :items="getUserGroupsList"
      :cache-items="true"
      @select="selectValue"
      v-model="val"
    >
      <template v-slot:append-item>
        <div
          v-if="lastPage && !filterSearch"
          v-observe-visibility="{
            callback: visibilityChanged,
          }"
          class="selectPagination"
        >
          <span class="pa-2">Loading more items ...</span>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
/**
 * Component of select with list of companies that are loaded async by chunks, with search
 */
import { mapGetters } from "vuex"

export default {
  name: "SelectCompaniesAsync",

  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String, Object],
    },
    keep: {
      type: String,
      default: "_key,name",
    },
  },

  data: () => ({
    filterSearch: null,
    selectPagination: {
      p: 1,
      pp: 500,
    },
  }),

  mounted: function () {
    this.fetchItems()
  },

  watch: {
    selected(val) {
      this.$emit("update:selected", val)
    },
    filterSearch() {
      this.selectPagination.p = 1
      this.fetchItems()
    },
    value: {
      handler: function (val, oldVal) {
        if (val && val !== oldVal) {
          // @todo return later
          // this.getDataForSelected()
        }
      },
      immediate: true,
    },
  },

  methods: {
    visibilityChanged(e) {
      if (this.lastPage) {
        e && this.nextPage()
      }
    },
    startPage() {
      this.selectPagination.p = 1
    },
    nextPage() {
      let maxPages = Math.ceil(this.userGroupsCount / this.selectPagination.pp)
      if (this.selectPagination.p <= maxPages - 1) {
        this.filterEnginesLoading = true
        this.selectPagination.p += 1
        this.fetchItems()
      }
      this.filterEnginesLoading = false
    },
    fetchItems() {
      let fetchData = {
        params: {
          p: this.selectPagination.p,
          // @todo later return this when will be possible to fetch all companies list
          // pp: this.selectPagination.pp,
          pp: 500,
          keep: this.keep,
        },
      }
      //if (this.filterSearch) fetchData.params.search = this.filterSearch
      if (this.filterSearch) fetchData.params.search = this.filterSearch
      this.$store.dispatch("getUserGroups", fetchData)
    },
    selectValue() {
      this.filterSearch = ""
    },
    getDataForSelected() {
      this.$store.dispatch("engineSelectedInlist", {
        params: {
          _key: this.value,
        },
      })
    },
  },

  computed: {
    ...mapGetters({
      getUserGroupsList: "getUserGroupsList",
      userGroupsCount: "getUserGroupsCount",
    }),
    lastPage() {
      let flag =
        this.userGroupsCount -
          this.selectPagination.p * this.selectPagination.pp >
        0
      return flag
    },
    val: {
      get() {
        return this.value
      },
      set() {},
    },
  },
}
</script>
